import axiosRequest from "@/utils/axiosRequest";

export function getSshCustomer(data) {
  return axiosRequest({
    url: `/customer/getSshInfo`,
    data,
    method: "post"
  });
}

export function getSshPayee(data) {
  return axiosRequest({
    url: `/customer/getSshPayee`,
    data,
    method: "post"
  });
}

export function getSshRecruiting(data) {
  return axiosRequest({
    url: `/customer/getSshRecruiting`,
    data,
    method: "post"
  });
}

export function saveCustomer(data) {
  return axiosRequest({
    url: `/customer/saveCustomer`,
    data,
    method: "post"
  });
}

export function addCustomer(data) {
  return axiosRequest({
    url: `/customer/addCustomer`,
    data,
    method: "post"
  });
}

export function updateCustomer(data) {
  return axiosRequest({
    url: `/customer/updateCustomer`,
    data,
    method: "post"
  });
}

export function confirmCustomer(data) {
  return axiosRequest({
    url: `/customer/confirmCustomer`,
    data,
    method: "post"
  });
}

export function cancelCustomer(data) {
  return axiosRequest({
    url: `/customer/cancelCustomer`,
    data,
    method: "post"
  });
}

export function delCustomer(data) {
  return axiosRequest({
    url: `/customer/delCustomer`,
    data,
    method: "post"
  });
}

export function rerunCustomer(data) {
  return axiosRequest({
    url: `/customer/rerunCustomer`,
    data,
    method: "post"
  });
}

export function listCustomer(data) {
  return axiosRequest({
    url: `/customer/list`,
    data,
    method: "post"
  });
}

export function countInfo(data) {
  return axiosRequest({
    url: `/customer/countInfo`,
    data,
    method: "post"
  });
}

export function customerDetail(data) {
  return axiosRequest({
    url: `/customer/getInfo`,
    data,
    method: "post"
  });
}

export function listPayee(data) {
  return axiosRequest({
    url: `/customer/payeeList`,
    data,
    method: "post"
  });
}

export function addPayee(data) {
  return axiosRequest({
    url: `/customer/addPayee`,
    data,
    method: "post"
  });
}

export function getKbnList(data) {
  return axiosRequest({
    url: `/customer/getKbnList`,
    data,
    method: "post"
  });
}

export function getSubKbnList(data) {
  return axiosRequest({
    url: `/customer/getSubKbnList`,
    data,
    method: "post"
  });
}

export function getLocalKbnList(data) {
  return axiosRequest({
    url: `/customer/getLocalKbnList`,
    data,
    method: "post"
  });
}

export function getSubLocalKbnList(data) {
  return axiosRequest({
    url: `/customer/getSubLocalKbnList`,
    data,
    method: "post"
  });
}

export function getSubLocalKbnList2(data) {
  return axiosRequest({
    url: `/customer/getSubLocalKbnList2`,
    data,
    method: "post"
  });
}

export function getSubLocalKbnList3(data) {
  return axiosRequest({
    url: `/customer/getSubLocalKbnList3`,
    data,
    method: "post"
  });
}

export function getCampaignList(data) {
  return axiosRequest({
    url: `/customer/getCampaignList`,
    data,
    method: "post"
  });
}


export function getMemberList(data) {
  return axiosRequest({
    url: `/customer/getMemberList`,
    data,
    method: "post"
  });
}

export function getAllMemberList(data) {
  return axiosRequest({
    url: `/customer/getAllMemberList`,
    data,
    method: "post"
  });
}

export function getManagerList(data) {
  return axiosRequest({
    url: `/customer/getManagerList`,
    data,
    method: "post"
  });
}

export function getParentList(data) {
  return axiosRequest({
    url: `/customer/getParentList`,
    data,
    method: "post"
  });
}

export function getZipInfo(data) {
  return axiosRequest({
    url: `/customer/getZipInfo`,
    data,
    method: "post"
  });
}

export function getRateByName(data) {
  return axiosRequest({
    url: `/customer/getRateByName`,
    data,
    method: "post"
  });
}

export function getScndMbList(data) {
  return axiosRequest({
    url: `/customer/getScndMbList`,
    data,
    method: "post"
  });
}

export function getAdvtidList(data) {
  return axiosRequest({
    url: `/customer/getAdvtidList`,
    data,
    method: "post"
  });
}

export function getAptstsInfo(data) {
  return axiosRequest({
    url: `/customer/getAptstsInfo`,
    data,
    method: "post"
  });
}

export function updateAptsts(data) {
  return axiosRequest({
    url: `/customer/updateAptsts`,
    data,
    method: "post"
  });
}

export function getOpsttsInfo(data) {
  return axiosRequest({
    url: `/customer/getOpsttsInfo`,
    data,
    method: "post"
  });
}

export function updateOpstts(data) {
  return axiosRequest({
    url: `/customer/updateOpstts`,
    data,
    method: "post"
  });
}

export function getApltdtInfo(data) {
  return axiosRequest({
    url: `/customer/getApltdtInfo`,
    data,
    method: "post"
  });
}

export function updateApltdt(data) {
  return axiosRequest({
    url: `/customer/updateApltdt`,
    data,
    method: "post"
  });
}

export function getCstcmdInfo(data) {
  return axiosRequest({
    url: `/customer/getCstcmdInfo`,
    data,
    method: "post"
  });
}

export function updateCstcmd(data) {
  return axiosRequest({
    url: `/customer/updateCstcmd`,
    data,
    method: "post"
  });
}

export function chkNameSr(data) {
  return axiosRequest({
    url: `/customer/chkNameSr`,
    data,
    method: "post"
  });
}
