import axiosRequest from "@/utils/axiosRequest";

export function addUser(data) {
  return axiosRequest({
    url: `/adminuser/addUser`,
    data,
    method: "post"
  });
}

export function updateUser(data) {
  return axiosRequest({
    url: `/adminuser/updateUser`,
    data,
    method: "post"
  });
}

export function deleteUser(data) {
  return axiosRequest({
    url: `/adminuser/deleteUser`,
    data,
    method: "post"
  });
}

export function listUser(data) {
  return axiosRequest({
    url: `/adminuser/listUser`,
    data,
    method: "post"
  });
}

export function userDetail(data) {
  return axiosRequest({
    url: `/adminuser/userDetail`,
    data,
    method: "post"
  });
}

export function listGroup(data) {
  return axiosRequest({
    url: `/adminuser/listGroup`,
    data,
    method: "post"
  });
}

export function addGroup(data) {
  return axiosRequest({
    url: `/adminuser/addGroup`,
    data,
    method: "post"
  });
}

export function updateGroup(data) {
  return axiosRequest({
    url: `/adminuser/updateGroup`,
    data,
    method: "post"
  });
}

export function removeGroup(data) {
  return axiosRequest({
    url: `/adminuser/removeGroup`,
    data,
    method: "post"
  });
}

export function groupDetail(data) {
  return axiosRequest({
    url: `/adminuser/groupDetail`,
    data,
    method: "post"
  });
}

export function resetUserPwd(data) {
  return axiosRequest({
    url: `/adminuser/resetPwd`,
    data,
    method: "post"
  });
}

export function getPpUserList(data) {
  return axiosRequest({
    url: `/adminuser/getPpUserList`,
    data,
    method: "post"
  });
}